/*****
1  - General code					|||		Line No : 11
2  - Common Code 					|||		Line No : 54
3  - Index, Index 1, Index 2 		|||		Line No : 208
4  - Index 3						|||		Line No : 400
5  - Index 4						|||		Line No : 600
6  - Index 5						|||		Line No : 1020
7  - Inner Header					|||		Line No : 1251
8  - Blog 							|||		Line No : 1324
9  - Contact Form					|||		Line No : 1424
10 - portfolio 						|||		Line No : 1500
11 - Isotopes						|||		Line No : 1553
12 - About us 						|||		Line No : 1580
13 - Responsive Menu				|||		Line No : 1610
14 - Profile				 		|||		Line No : 1634
15 - Bottom Information Shortcode	|||		Line No : 1974
16 - copyright						|||		Line No : 2007
17 - Demo Panel						|||		Line No : 2025
18 - Writer Resume					|||		Line NO : 2136
		 
******/

	/*****	General Code *****/
		
body {
	position:relative;
	margin:0px;
	padding:0px;
	width: 100%;
	float:left;
}
textarea, input[type="text"]:focus { outline:none; }
a, a:focus, a:hover { outline:none; text-decoration:none; }
li { list-style:none; }
h1, h2, h3, h4, h5, h6, p, ol, ul, p {
	margin:0px;
	padding:0px;
}
h2 {
	font: 42px "Intro";
	color:#000;
	letter-spacing:2px;
	text-transform:uppercase;
}
p {
	font: 15px/30px "Noto Sans";
	color:#434242;
	letter-spacing:0.4px;
}
h3 a {
	font: 900 35px/48px "Intro ";
	color:#fefefe;
	letter-spacing:1px;
	text-transform:uppercase;
}
section, header  {
    float: left;
    width: 100%;
    position: relative;
    z-index: 1;
}
iframe{
    border:0;
    width: 100%;
    float: left;
	height:auto;
}

	/*****	Common Code *****/
	
.layout.grey { background:#fafafa; }
.container.white { background:#Fff; margin-top: 450px; border-right: 2px solid #e8e8e8;  }
a.backto-top {
	display:block;
	height: 50px;
	width:50px;
	text-align:center;
	line-height: 50px;
	color:#fff;
	background:#e65650;
	position:fixed;
	left:100%;
	top:100%;
	margin-top:-50px;
	margin-left: -50px;
	z-index:9999999999;
}
a.backto-top:hover {
	opacity: 1;
}
.grey {
	float:left;
	width:100%;
	background:#f8f8f8;
}
header.sticky {
	position: fixed;
	left:0;
	top:0;
	z-index:99999999999999;
}
.sect-gap {
	float:left;
	width:100%;
	padding: 100px 0px;
	position:relative;
	z-index: 99;
}
.container.full { width: 100%; padding:0px; }
.container.full .row { padding:0px; margin:0px; }
.container.full div { padding:0px; }
.merged div { padding:0; }
.no-top { padding-top:0px; }
.no-bottom { padding-bottom:0px; }
.layout.boxed {
	float: none;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 1230px;
	
    -webkit-box-shadow: 0 0 1px #121212;
    -moz-box-shadow: 0 0 1px #121212;
    -ms-box-shadow: 0 0 1px #121212;
    -o-box-shadow: 0 0 1px #121212;
    box-shadow: 0 0 1px #121212;
}
.parallax {
    height: 100%;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    -moz-background-size: cover!important;
	-webkit-background-size: cover!important;
	-o-background-size: cover!important;
	-ms-background-size: cover!important;
	 background-size: cover!important;
	 
    -webkit-background-attachment: fixed!important;
	-moz-background-attachment: fixed!important;
	-ms-background-attachment: fixed!important;
	-o-background-attachment: fixed!important;
	background-attachment: fixed!important;
	
    width: 100%;
    z-index: -1;
    
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}
.blacky:before,
.whity:before {
    background: none repeat scroll 0 0 #000000;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}
.whity:before {
	background:#fff;
	opacity: 0.6;
}
.fixed-bg {
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	z-index:-1;
}
.fb { background:#627aad; }
.gplus { background:#d62d20; }
.drible { background:#ed4a89; }
.reddit { background:#ff4500; }
.title.style1 {
	float: left;
	width:100%;
	text-align:center;
	padding-bottom:60px;
}
.title.style1 h2 {
	float:left;
	width:100%;
	position:relative;
	padding-bottom:30px;
}
.title.style1 h2:before {
	content:'';
	height: 4px;
	width: 20px;
	background:#d64123;
	position:absolute;
	left:50%;
	top:100%;
	margin-left:-10px;	
}
.title.style1 p {
	float:left;
	width:100%;
	font: 20px "Lato";
	color:#000;
	letter-spacing:1px;
	padding-top: 20px;
}
a.readmore {
	display:inline-block;
	width: 150px;
	height: 50px;
	border:2px solid #e65650;
	color:#e65650;
	font: bold 14px/46px "Lato";
	letter-spacing:0.4px;
	
	-moz-transition: all 0.2s ease-in;
	-webkit-transition: all 0.2s ease-in;
	-ms-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	 transition: all 0.2s ease-in;
}
a.readmore:hover {
	background: #e65650;
	color:#FFF;
}

				/*****	Index, Index 1, Index 2 	****/

.main-menu {
	float:left;
	width:100%;
}
.fancy-header  {
	width:100%;
	float:left;
	position:relative;
}
.fancy-header img {
	float:left;
	width:100%;
}
.fixed-title {
	position:absolute;
	left:47%;
	top:25%;
}
.fixed-title.inner {
	position: absolute;
	left:55%;
	top:15%;
	width: 550px;
}
.fixed-title h2 {
	float:left;
	width:100%;
	padding-left: 2px;
	font: 30px/55px "Lato";
	color:#FFF;
	letter-spacing:2px;
	text-transform:capitalize;
}
.fixed-title h1 {
	float:left;
	width:100%;
	font: 900 35px "Intro";
	color:#FFF;
	letter-spacing:3px;
	padding-bottom: 10px;
}
.fixed-title p {
	font: 16px "Noticia Text";
	color:#e65650;
	letter-spacing:1px;
}
.fixed-title span {
	padding-top: 25px;
	font: 15px/30px "Lato";
	color:#ddd;
	letter-spacing:0.5px;
	float:left;
	width:80%;
	padding-bottom: 40px;
}
.fixed-title > a {
	display:inline-block;
	padding: 20px 30px;
	border: 2px solid #e65650;
	font: bold 15px/24px "Lato";
	color:#e65650;
	letter-spacing:0.4px;
}
.fixed-title > a i {
	padding-right: 10px;
	font-size:20px;
	color:#e65650;
}
.fixed-title.fixed {
	position:absolute;
	left:55%;
	top:15%;
}
.fixed-social { text-align:center; width:100%; float: left; margin-top:-31px; }
.fixed-social ul {
	display: inline-block;
	padding:0px;
}
.fixed-social ul li { display:inline-block; float:none; margin-right: 7px; }
.mynav img,
.mynav ul {
	float:left;
	width:100%;
	text-align:center;
}
.fancy-nav {
	width: 100%;
	float:left;
	position:relative;
	text-align:center;
	overflow:hidden;
	z-index:2;
}
.fancy-nav:before {
	content:'';
	height: 100%;
	width:100%;
	background:rgba(0,0,0,0.8);
	position:absolute;
	left:0;
	top:0;
	z-index: 1;
	
	-moz-transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
	-ms-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	 transition: all 0.3s ease-in;
}
.fancy-nav:hover img {
	-moz-transform: scale(1.2);
}
.fancy-nav img { 
	float:left; 
	width:100%;
	overflow:hidden;
	z-index:-1;
	
	-moz-transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
	-ms-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	 transition: all 0.3s ease-in;
}
.fancy-nav ul { 
	position:absolute; 
	left:0;
	top:0;
	text-align:center;
	width:100%;
	z-index:5;
	padding-top: 70px;
	-moz-transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
	-ms-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	 transition: all 0.3s ease-in;
}
.fancy-nav.large ul {
	padding-top: 130px;
}
.fancy-nav:hover ul {
	margin-top: -20px;
}
.fancy-nav ul li { display:block; }
.fancy-nav ul li:first-child > a {
	display: inline-block;
	width:75px;
	height: 75px;
	background: #e65650;
	color:#FFF;
	text-align: center;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
	-webkit-border-radius: 100%;
	border-radius: 100%;
	
	-moz-transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
	-ms-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	 transition: all 0.3s ease-in;
	
}
.fancy-nav ul li:first-child > a i {
	font-size: 30px;
	color:#FFF;
	line-height: 75px;
	
}
.fancy-nav ul li:first-child > a:hover {
	-moz-transform: rotate(360deg);
	-webkit-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	-o-transform: rotate(360deg);
	 transform: rotate(360deg);
}
.fancy-nav ul li:last-child > a {
	display: block;
	font: 24px "Intro";
	color:#FFF;
	text-transform:uppercase;
	text-align:center;
	letter-spacing:1px;
	padding-top: 30px;
}
.fancy-nav ul li:last-child > a:hover {
	color:#e65650;
}
	/**********	Index 3 **********/
	
header.style1 {
	float:left;
	width:100%;
	position:relative;
}
header.style1 .topbar {
	float:left;
	width:100%;
	height: 150px;
	background:#282320;
	text-align:center;
}
header.style1 .logo {
	float:left;
	line-height: 150px;
	text-align:center;
	width:100%;
	margin:0 auto;
}
.logo a { margin:0 auto; }
header.style1 .social {
	position:absolute;
	left:10%;
	top: 0;
	width:auto;
}
.aboutus.style1 {
	float:left;
	width:100%;
	position:relative;
}
.author-thumb {
	float:left;
	width: 300px;
    display: inline-block;
    left: 50%;
	top:-90%;
    margin-left: -150px;
    position: absolute;
	border:20px solid #fff;
	
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
	 border-radius: 100%;
}
.author-thumb img {
	width: 100%;
	float:left;
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
	 border-radius: 100%;
}
.aboutus.style1 .about-meta {
	float:left;
	width:100%;
}
.aboutus.style1 .about-meta h3 {
	padding-top: 20px;
	font: 40px/55px "Lato";
	color:#2d2d2d;
	letter-spacing:1px;
}
.aboutus.style1 .about-meta h1 {
	padding-top: 10px;
	font: 50px "Intro";
	color:#2d2d2d;
	letter-spacing:5px;
	text-transform:uppercase;
}
.aboutus.style1 .about-meta span {
	padding: 20px 0px 30px 0px;
	display:block;
	width:100%;
	font: 20px "Noticia Text";
	color:#e65650;
	letter-spacing:1px;
	text-transform:uppercase;	
}
.aboutus.style1 .about-meta p {
	width: 85%;
	margin:0 auto;
	font: 20px/40px "Lato";
	color:#666666;
	letter-spacing:0.4px;
}
.sect-photography { float:left; width: 100%; position:relative; padding: 100px 60px; }
.photography-carousel {
	float:left;
	width:100%;
	position:relative;
}
.photography-carousel .photo-item {
	float:left;
	width:100%;
}
.photo-item img { float:left; width:100%; margin-bottom: 30px; }
.photo-carousel {
	float:left;
	width:100%;
}
.photo-carousel img { float:left; width: 100%; }
.photography-carousel .owl-nav {
    color: transparent;
    float: left;
    left: 50%;
    position: absolute;
    top: 100%;
	width:80px;
	margin-left:-80px;
}
.photography-carousel .owl-prev,
.photography-carousel .owl-next {
    background: none repeat scroll 0 0 #e65650;
    float: left;
    height: 65px;
    left: 0;
    position: absolute;
    top: 0;
    width: 80px;
	text-align:center;
	
	-moz-border-radius: 15px;
	-webkit-border-radius: 15px;
	-ms-border-radius: 15px;
	-o-border-radius: 15px;
	 border-radius: 15px;
}
.photography-carousel .owl-next {
    left: 100%;
    top: 0;
	margin-left:20px;
}
.photography-carousel .owl-prev:before {
	content:'\f104';
	font-family:fontawesome;
	color:#FFF;
	line-height: 65px;
	font-size: 20px;
	margin:0 auto;
	display:block;
}
.photography-carousel .owl-next:before {
	content:'\f105';
	font-family:fontawesome;
	color:#FFF;
	line-height: 65px;
	font-size: 20px;
	margin:0 auto;
	display:block;
}
.photo-carousel .owl-nav {
    color: transparent;
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
	margin-top: -30px;
}
.photo-carousel .owl-prev {
    background: none repeat scroll 0 0 #e65650;
    float: left;
    height: 60px;
    width: 60px;
	display:inline-block;
}
.photo-carousel .owl-prev:before {
	content: '\f104';
	font-family: fontawesome;
	color:#FFF;
	line-height: 60px;
	font-size: 20px;
	display:table;
	margin:0 auto;
}
.photo-carousel .owl-next {
    background: none repeat scroll 0 0 #e65650;
    float: left;
    height: 60px;
    left: 100%;
    margin-left: -60px;
    position: absolute;
    width: 60px;
}
.photo-carousel .owl-next:before {
	content:'\f105';
	font-family: fontawesome;
	font-size: 20px;
	color:#FFF;
	line-height: 60px;
	display:table;
	margin:0 auto;
}
	
			/*****	Index 04	*****/
		
.topmenu {
	height: 150px;
	background:#282320;
	width:100%;
}
.menu {
	float:left;
	width:100%;
}
.menu ul { float:left; width: 100%; line-height: 150px; }
.menu ul li { float:left; }
.menu ul li a {
	display:inline-block;
	font: 16px "Lato";
	color:#FFF;
	text-transform:uppercase;
	letter-spacing: 0.5	px;
	padding-right: 30px;
}
.menu ul li a i {
	padding-right: 10px;
}
.topmenu .logo { line-height: 150px; }
.about-author {
	float:left;
	width:100%;
	text-align:center;
	padding: 120px 0px;
}
.autho-thumb {
	margin:0 auto;
	width: 220px;
	height:220px;
	border: 10px solid #FFF;
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
	 border-radius: 100%;
}
.autho-thumb img {
	float:left;
	width:100%;
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
	 border-radius: 100%;
}
.about-author h1{
	padding-top:50px;
	color:#FFF;
	font: 45px "Intro";
	letter-spacing: 2px;
	text-transform:uppercase;
}
.about-author p {
	padding: 10px 0px 30px 0px;
	font: 20px "Noticia Text";
	color: #e65650;
	letter-spacing:1px;
}
.about-author > a {
	display: inline-block;
	padding: 25px;
	border: 2px solid #e65650;
	color:#e65650;
	font: bold 15px "Lato";
	letter-spacing:0.3px;
}
.about-author > a i {
	padding-right: 10px;
	font-size: 20px;
}
.feature {
	width:100%;
	float:left;
	text-align:center;
}
.feature i {
	font-size: 50px;
	color:#303030;
}
.feature h4 {
	padding: 20px 0px 10px 0px;
	float:left;
	width:100%;
	font: bold 19px "lato";
	color:#303030;
	letter-spacing:1px;
	text-align:center;
}
.feature p {
	margin:0 auto;
	width: 73%;
	font: 15px/25px "Lato";
	color:#5c5b5b;
	letter-spacing:0.4px;
}
.biography {
	float:left;
	width:100%;
	background:#FFF;
	padding: 70px;
}
.biography ul { float:left; width:100%; }
.biography ul li { display:block; width:100%; float:left; padding: 15px 0px; }
.biography ul li i { float:left; font-size: 24px; color:#000; padding-right:30px;  }
.biography ul li h4 {
	float:left; 
	font: bold 24px "Intro";
	color: #000;
	letter-spacing:1px;
	text-transform:uppercase;
}
.biography ul li span {
	float:left;
	width:100%;
	color:#4f4f4f;
	font: 18px "Noticia Text";
	letter-spacing:0.4px;
}
.work-carousel {
	float:left;
	width: 100%;
	position:relative;
}
.mywork {
	float:left;
	width:100%;
}
.mywork-head {
	float: left;
	width: 80%;
	background:#e65650;
	padding: 15px 0px;
	text-align:center;
}
.mywork-head h4 {
	float: left;
	width:100%;
	text-align:center;
	font: 24px "Intro";
	color:#fff;
	letter-spacing:2px;
	text-transform:uppercase;
}
.mywork-meta {
	float:left;
	width:100%;
	background:#FFF;
	padding: 60px;
}
.mywork-meta h5 {
	float:left;
	width:100%;
	font: bold 20px "Lato";
	color:#2c2c2c;
}
.mywork-meta p {
	padding: 30px 0px;
	float:left;
	width:100%;
	font: 15px/30px "Lato";
	color:#5a5a5a;
	letter-spacing:0.4px;
}
.mywork-meta ul { float:left; width:100%; list-style-type: circle; }
.mywork-meta ul li { 
	display:block; 
	padding: 15px 0px;
	font: bold 20px "Lato";
	color:#2c2c2c;
	letter-spacing:4px;
}
.mywork-meta > a {
	margin-top: 30px;
	display:inline-block;
	width: 180px;
	height: 60px;
	color:#fff;
	background:#797979;
	font: bold 15px/60px "Lato";
	letter-spacing: 0.3px;
	text-transform:uppercase;
	text-align:center;
}
.price-table {
	float:left;
	width:100%;
	position:relative;
	border:4px solid #000;
	padding: 70px 0px;
	text-align:center;
	-moz-border-radius: 20px;
	-webkit-border-radius: 20px;
	-o-border-radius: 20px;
	-ms-border-radius: 20px;
	 border-radius: 20px;
}
.price-table i {
	float:left;
	width:100%;
	text-align:center;
	font-size: 40px;
	color:#121212;
	position:relative;
}
.price-table h4 {
	padding: 20px 0px 50px 0px;
	float:left;
	width:100%;
	text-align:center;
	font: bold 40px "lato";
	color:#121212;
	letter-spacing:0.4px;
	position:relative;
}
.price {
	float:left;
	width:100%;
	padding: 20px 0px;
	background:#000;
	position:relative;
}
.price h3 {
	font:bold 30px "Lato";
	color:#FFF;
	letter-spacing:0.4px;
	position:relative;
}
.price h3 span:before{
	content: '/';
	display:inline-block;
	padding: 0px 10px;
}
.price h3 span {
	font: 20px "Lato";
	color:#FFF;
}
.price-table ul {
	padding-top: 50px;
	float:left;
	width:100%;
	text-align:center;
}
.price-table ul li {
	padding: 20px 0px;
	font: 20px "Lato";
	color:#313131;
	letter-spacing:4px;
	position:relative;
}
.price-table ul li:after {
	content:'';
	position:absolute;
	left:50%;
	top:100%;
	width: 26px;
	height: 2px;
	background: #000;
	margin-left: -13px;
}
.price-table a {
	display:inline-block;
	padding: 10px 20px;
	border: 4px solid #000;
	text-align:center;
	text-transform:uppercase;
	font: bold 20px "Lato";
	color:#000;
	margin-top:50px;
	position:relative;
}
.myprogress {
	float:left;
	width:100%;
	text-align:center;
}
.myprogress i {
	display:inline-block;
	text-align:center;
	border: 4px solid #dfdfdf;
	font-size: 32px;
	color:#FFF;
	padding: 20px;
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
	 border-radius: 100%;
}
.myprogress span {
	padding: 30px 0px 20px 0px;
	float:left;
	width:100%;
	text-align:center;
	color:#e65650;
	font: 40px "Intro";
	letter-spacing:2px;
}
.myprogress p {
	float:left;
	width:100%;
	text-align: center;
	font: 20px "Lato";
	color:#fff;
	letter-spacing:4px;
}
.bloglist {
	float:left;
	width:100%;
	margin-top: 40px;
}
.blogstyle1 {
	float:left;
	width:100%;
	background:#FFF;
}
.blogstyle1 .blog-img,
.blogstyle1 .blog-img img {
	float:left;
	width:100%;
}
.blogstyle1 .blog-meta.left { 
	float: left; 
	width:100%; 
	position: relative; 
}
.blogstyle1 .blog-meta.right {
	float:right;
	width:100%;
	position:relative;
}
.blogstyle1:first-child { margin-top: 40px; }
.blogstyle1 .blog-meta i {
	margin-top: 40px;
	margin-left: 60px;
	float:left;
	width:100%;
	height: 90px;
	width: 90px;
	border: 1px solid #e65650;
	text-align:center;
	font-size: 46px;
	line-height: 90px;
	color:#e65650;
}
.blogstyle1 .blog-meta h4 {
	margin-top: 20px;
	margin-bottom: 15px;
	padding-left: 60px;
	float: left;
	width:100%;
	font: bold 26px "Lato";
	color:#2c2c2c;
}
.blogstyle1 .blog-meta p {
	width: 100%;
	padding-bottom: 20px;
	padding-left: 60px;
	padding-right: 140px;
	float:left;
	font: 15px/30px "Lato";
	color: #5a5a5a;
	letter-spacing:0.4px;
}
.blogstyle1 .blog-meta a.readmore {
	display:block;
	margin-left: 60px;
	float:left;
	text-align:center;
	background:#d2534e;
	color: #FFF;
	text-transform:uppercase;
	border:none;
}
.newsletter {
	float:left;
	width:100%;
}
.newsletter h4 {
	padding-top:11px;
	float:right;
	width:100%;
	font: 25px "Intro";
	color:#FFF;
	letter-spacing:2px;
	text-align:right;
}
.newsletter ul { float:left; width:100%; }
.newsletter ul li { float:left; }
.newsletter ul li:first-child {
	width: 60%;
}
.newsletter ul li:last-child {
	width: 25%;
}
.newsletter li [type="text"] {
	font: 12px "Lato";
	color:#FFF;
	height: 50px;
	padding-left:20px;
	width:100%;
	background: rgba(230, 86, 80, 0.15);
	border:2px solid #fff;
}
.newsletter li button {
	border:none;
	height: 50px;
	background:#ca4d47;
	border:2px solid #fff;
	border-left: none;
	width: 100%;
	font: 12px "Lato";
	color:#FFF;
	text-transform: uppercase;
}

			/*****	index 5	*****/
			
.fancy-menu {
	float:left;
	width:100%;
}
.fancy-menu .logo1 { width: 10%; float:left; }
.fancy-menu .logo1 a,
.fancy-menu .logo1 img {
	float:left;
	width:100%;
}
.fancy-menu nav { float:left; width: 90%; }
.fancy-menu ul {
	width:100%;
	float:left;
}
.fancy-menu ul li {
	float:left;
	width: 16.666666%;
	position:relative;
	overflow:hidden;
}
.fancy-menu ul li img { 
	width: 100%; 
	height: auto; 
	float:left; 
	
	-moz-transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
	-ms-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	 transition: all 0.3s ease-in;
}
.menu-link {
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.5);
	z-index: 99;
	text-align:center;
	vertical-align:middle;
	display:flex;
	align-items: center;
	justify-content: center;
}
.menu-link:hover + img {
	-moz-transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-o-transform: scale(1.2);
	-ms-transform: scale(1.2);
	 transform: scale(1.2);
}
.fancy-menu ul li .menu-link a {
	font: 600 16px "Lato";
	color:#FFF;
	text-align:center;
	text-transform:uppercase;
	letter-spacing:1px;
}
.fancy-menu ul li a:hover { color:#e65650; }
.accordian,
.accordian ul {
	float:left;
	width:100%;
}
.accordian ul li {
	float:left;
	width:100%;
	margin-bottom:12px;
}
.accordian ul > li a {
	padding: 25px 40px;
	background:#fff;
	float:left;
	width:100%;
	display:block;
	color:#686868;
}
.accordian ul > li a.active {
	background:#e65650;
}
.accordian ul > li a.active i,
.accordian ul > li a.active span,
.accordian ul > li a.active h6 {
	color:#FFF;
}
.accordian ul > li a i { 
	padding-top: 3px;
	padding-right: 20px;
	float:left;
	font-size: 18px;
	color:#686868;
}
.accordian ul > li a span {
	padding-top: 3px;
	font-size: 18px;
	color:#686868;
	float:right;
}
.accordian ul > li a h6 {
	float:left;
	font: 18px "Lato";
	color:#686868;
	letter-spacing:1px;
}
.accordian ul > li .panel-desc {
	float:left;
	width: 100%;
	background:#4d4d4d;
	padding: 40px;
}
.panel-desc p {
	font: 15px/26px "Open Sans";
	color:#e5e5e5;
	letter-spacing:0.4px;
}

.mobile { float:left; width:100%; }
.mobile img { float:left; max-width:  100%;  }
.myvideos {
	float:left;
	width:100%;
	text-align:center;
	background:#FFF;
	padding: 40px;
}
.video {
	float:left;
	width:100%;
	position:relative;
}
.video > img { float:left; width:100%; }
.video .overlay { 
	position: absolute;
	left:0;
	top:0;
	height: 100%;
	width: 100%;
	background:rgba(0,0,0,0.6);
}
.video .overlay i {
	padding: 24px;
	font-size: 30px;
	border:1px solid #e65650;
	color:#e65650;
	position:absolute;
	left:50%;
	top: 50%;
	margin-top:-27px;
	margin-left:-27px;
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
	 border-radius: 100%;
}
.video-meta {
	float:left;
	width:100%;
}
.video-meta h3 {
	font: bold 26px "Lato";
	color:#2c2c2c;
	letter-spacing:1px;
	text-transform:uppercase;
	padding: 40px 0px 20px 0px;
}
.video-meta i {
	color:#e65650;
	font-size:20px;
}
.video-meta span {
	font: 18px "Lato";
	color:#e65650;
	letter-spacing:0.5px;
}
#video-carousel {
	float:left;
	width:100%;
}
.video-list {
	float:left;
	width:100%;
	margin-bottom: -30px;
}
.video-nav {
	float:left;
	width:100%;
	margin-bottom: 30px;
	position:relative;
}
.small-thumb,
.small-thumb img {
	float:left;
	width:100%;
	position:relative;
}
.video-desc {
	padding-top: 65px;
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.6);
	text-align:center;
}
.video-desc h6 {
	float:left;
	width:100%;
}
.video-desc h6 a {
	display:block;
	padding-bottom: 15px;
	color:#FFF;
	font: 22px "Intro";
	letter-spacing:1px;
	text-transform:uppercase;
}
.video-desc i {
	color:#e65650;
	font-size: 16px;
	padding-right: 10px;
}
.video-desc span {
	font: bold 15px "Lato";
	color:#e65650;
	letter-spacing:0.4px;
}

	/******* Inner Header	*******/
	
.inner-header {
	float:left;
	width:100%;
	position:absolute;
	left:53%;
	top:0%;
}
.inner-header_bg > img {
    float: left;
    width: 100%;
}
.top-close {
	float:left;
	width:100%;
}
a.btn-close {
	float:right;
	display:block;
	width: 70px;
	height: 70px;
	background:#e65650;
	text-align:center;
	margin-right: 110px;
	
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-o-border-radius: 100%;
	-ms-border-radius: 100%;
	border-radius: 100%;
}
a.btn-close i {
	font-size: 34px;
	line-height: 70px;
	color:#FFF;
}
.social {
	padding-top: 40px;
	float:left;
	width:100%;
}
.social li { float:left; margin-right:20px;  }
.social li a {
	display:inline-block;
	width: 62px;
	height: 62px;
	text-align:center;
	
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	-o-border-radius: 100%;
	-ms-border-radius: 100%;
	border-radius: 100%;
	
	-moz-transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
	-ms-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	 transition: all 0.3s ease-in;
}
.social li a i {
	font-size: 20px;
	color:#FFF;
	line-height: 62px;
}
.social li a:hover {
	margin-top: -10px;
}

		/******	Blog 	******/

.blog-listing {
	float:left;
	width:100%;
	margin-bottom: -50px;
}	
.blog-list {
	float:left;
	width:100%;
	margin-bottom: -50px;
}
.blog-list .blogstyle{
	float: left;
	width:100%;
	margin-bottom: 50px;
	text-align: center;
	padding: 120px 150px;
	position:relative;
	z-index: 2;
}
.blog-meta {
	float:left;
	width:100%;
	position:relative;
	z-index:4;
}
.blogstyle h3 { float:left; width: 100%; }
.blogstyle h3 a {
	display:block;
	float:left;
	width:100%;
	text-align:center;
	font: 35px/48px "Intro";
	color:#fefefe;
	letter-spacing:3px;
	text-transform:uppercase;
	position:relative;
	padding-bottom: 20px;
	margin-bottom: 40px;
	
	-moz-transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
	-ms-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	 transition: all 0.3s ease-in;
}
.blogstyle h3 a:before {
	content:'';
	height: 3px;
	width: 50px;
	margin-left: -25px;
	background: #e65650;
	position:absolute;
	left:50%;
	top:100%;
}
.blogstyle h3 a:hover { color:#e65650; }
.blogstyle p {
	font: 14px/30px "Noto Sans";
	letter-spacing: 0.4px;
	color:#f9f9f9;
	margin:0 auto;
	width: 81%;
	padding-bottom: 40px;
}
.blogstyle ul {
	padding-top: 50px;
	float:left;
	width:100%;
	display:block;
	text-align:center;
}
.blogstyle ul li { display:inline-block; }
.blogstyle ul li a {
	display:inline-block;
	height: 40px;
	width: 40px;
	background:#fff;
	margin-right: 20px;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	-webkit-border-radius: 100%;
	-o-border-radius: 100%;
	border-radius: 100%;
	
	-moz-transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
	-ms-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	 transition: all 0.3s ease-in;
}
.blogstyle ul li a i {
	line-height: 40px;
	color:#2f2f2f;
	text-align:center;
}
.blogstyle ul li a:hover { background:#e65650; }
.blogstyle ul li a:hover i { color:#FFF; }

				/****** Contact Form 	*****/
				
.contact-form {
	float:left;
	width:100%;
}
.contact-form.light { float: left; width: 100%; }
.contact-form label {
	width:100%;
	float:left;
	position:relative;
	margin-bottom:30px;
}
.contact-form label i {
    border-right: 1px solid #dddddd;
    color: #7f7f7f;
    font-size: 18px;
    left: 0;
    margin-top: -9px;
    padding-left: 30px;
    padding-right: 20px;
    position: absolute;
    top: 50%;
}
.contact-form.light [type="text"],
.contact-form.light textarea  { background:#fafafa; }
.contact-form [type="text"],
.contact-form textarea {
    background: none repeat scroll 0 0 #fff;
    border: 1px solid #f3f3f3;
    color: #d5d5d5;
    float: left;
    font: 14px "Lato";
    height: 60px;
    letter-spacing: 1px;
    padding-left: 100px;
    width: 100%;	
		
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	-o-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
}
.contact-form textarea {
	height: 300px;
	padding:30px;
	width:100%;
	resize:none;
}
.btn-submit {
	float:left;
	width:100%;
	text-align:center;
	padding-top: 50px;
}
.btn-submit button {
	border:none;
	height: 65px;
	width: 290px;
	display:inline-block;
	border: 2px solid #e65650;
	text-align:center;
	font: 20px "Intro";
	color:#e65650;
	background:transparent;
}
.btn-submit button i {
	color:#e65650;
	font-size: 22px;
	padding-right: 20px;
	display:inline-block;
	vertical-align:middle;
}

	/******	Portfolio 	******/
	
.portfolio-wrapper {
	float:left;
	width:100%;
}
.portfolio-wrapper .portfolio {
	float:left;
	width:100%;
}
.portfolio-wrapper .portfolio img { float:left; width:100%; }
.portfolioFilter {
	float:left;
	width:100%;
	text-align:center;
	padding-bottom:100px;
}
.portfolioFilter ul.white li a{ 
	color:#dfdfdf; 
	border-color:#5c5b5b; 
}
.portfolioFilter ul { float:left; width:100%; display:inline-block; }
.portfolioFilter ul li { display: inline-block; }
.portfolioFilter ul li a {
	display:inline-block;
	height: 60px;
	padding: 0px 30px;
	border:2px solid #393939;
	font: 18px/60px "Lato";
	color:#000;
	text-align:center;
	margin-right: 20px;
	-moz-transition: all 0.3s ease-out;
	-webkit-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	 transition: all 0.3s ease-out;
}
.portfolioFilter ul li a i {
	padding-right:20px;
	font-size: 23px;
}
.portfolioFilter ul li a:hover {
	border-color: #e65650;
	background:#e65650;
	color:#FFF;
}
.portfolioFilter ul li a:hover i { color:#FFF; }
.isotope-item {
    z-index: 2;
}

	/***** IsoTopes Setting 	****/
	
.isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
}
.isotope,
.isotope .isotope-item {
    -webkit-transition-duration: 0.8s;
	-ms-transition-duration: 0.8s;
	-o-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    transition-duration: 0.8s;
}
.isotope {
    -webkit-transition-property: height, width;
    -moz-transition-property: height, width;
	-ms-transition-property: height, width;
	-o-transition-property: height, width;
    transition-property: height, width;
}
.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
	-o-transition-property: -moz-transform, opacity;
	-ms-transition-property: -moz-transform, opacity;
    transition-property: transform, opacity;
}

	/*****	About Us 	*****/
	
.about {
	float:left;
	width:100%;
}
.about h2 {
	padding-bottom: 40px;
}
.about p { 
	float: left;
	width:100%;
	position:relative; 
	padding-bottom: 35px;
}
.about p:last-child:before {
	content:'';
	height: 3px;
	width: 60px;
	background:#a0a0a0;
	position:absolute;
	left:0;
	top:100%;
}
.single-img,
.single-img img {
	float:left;
	width:100%;
}

		/***** Responsive Navigation 	******/

.responsive { display:none; }
.responsive-nav {
	float:left;
	width:100%;
}
.responsive-nav ul { float:left; width:100%; }
.responsive-nav ul li { display:block; }
.responsive-nav ul li a {
	display:block;
	background:#333;
	color:#FFF;
	height: 50px;
	padding-left: 30px;
	width:100%;
	font-weight:bold;
	line-height: 50px;
	border-bottom: 1px solid #222;
}
.responsive-nav ul li a i {
	padding-right: 10px;
}

			/******	Profile	*****/
			
.aboutus {
	float:left;
	width:100%;
	text-align: center;
}
.aboutus p {
	width: 80%;
	margin:0 auto;
	font: 15px/30px "Lato";
	letter-spacing:0.4px;
	color:#5c5b5b;
}
.aboutus ul {
	float:left;
	width:100%;
	text-align:center;
	padding-top: 50px;
}
.aboutus ul li { display:inline-block; } 
.aboutus ul li a {
	display:inline-block;
	padding:20px 40px;
	border: 2px solid #e65650;
	font: bold 18px "Lato";
	color:#e65650;
	margin-left: 20px;
}
.title.style1.red {
	float:left;
	width:100%;
	text-align:center;
}
.title.red h3 {
	float:left;
	width:100%;
	font: 42px "Intro";
	letter-spacing:2px;
	color:#e65650;
	text-transform: uppercase;
	text-align:center;
	padding-bottom:10px;
}
.title.red span {
	text-align:center;
	display:block;
	width:50%;
	margin:0 auto;
	color:#ededed;
	font: 15px/30px "Lato";
	letter-spacing: 0.4px;
}
.project-list {
	float:left;
	width:100%;
	margin-bottom: -30px;
	padding-top: 60px;
}
.project {
	float: left;
	width:100%;
	padding:50px 0px;
	background: #343333;
	border-left: 2px solid #e65650;
	text-align:center;
	margin-bottom:30px;
}
.project i {
	font-size: 60px;
	color:#fff;
}
.project h3 {
	padding-top:20px;
	float:left;
	width:100%;
	text-align:center;
	font: 700 30px "Lato";
	color:#fffefe;
	letter-spacing:0.2px;
	text-transform:uppercase;
}
.project h3 span {
	display:block;
	padding-top: 10px;
	font: 300 22px "Lato";
	color:#fffefe;
	letter-spacing:0.4px;
	text-transform:uppercase;
}
.work-list {
	float:left;
	width:100%;
	margin-bottom:-50px;
}
.work {
	float:left;
	width:100%;
	padding: 90px 0px;
	position:relative;
	margin-bottom:50px;
}
.work-meta {
	float:left;
	width:100%;
	position:relative;
	z-index: 4;
}
.work-meta h3 {
	float:left;
	width:100%;
	font: 33px "Intro";
	color:#FFF;
	letter-spacing: 1px;
	text-transform:uppercase;
	text-align:center;	
}
.work-meta span {
	float:left;
	width:100%;
	padding: 10px 0px 20px 0px;
	display:block;
	font: 20px "Lato";
	color:#e65650;
	letter-spacing:0.3px;
	text-transform: uppercase;
	text-align:center;
}
.work-meta p {
	margin:0 auto;
	text-align: center;
	width: 60%;
	font: 15px/30px "Lato";
	color:#fff;
	letter-spacing:0.3px;
}
.testimonial-carousel,
.testimonial-carousel.light {
	float:left;
	width:100%;
}
.testimonial-carousel.transparent .testimonial {
	background: rgba(244, 244, 244, 0.06);
	border: 2px solid #f4f4f4;
}
.testimonial-carousel.transparent .testimonial h4 { color:#FFF; }
.testimonial-carousel.transparent .testimonial p {
	color:#bebebe;
}
#testimonial-transparent {
	float:left;
	width:100%;
	padding: 0px 40px;
}
#testimonial-transparent .owl-nav {
	color:transparent;
	position:absolute;
	left:0;
	top:50%;
	width:100%;
	margin-top: -60px;
}
#testimonial-transparent .owl-nav .owl-next,
#testimonial-transparent .owl-nav .owl-prev {
	float:right;
	height: 60px;
	width:60px;
	border:2px solid #ebebeb;
	text-align:center;
	position:absolute;
	left: 100%;
}
#testimonial-transparent .owl-nav .owl-prev {
	float:left;
	position:absolute;
	left:-5%;
}
#testimonial-transparent .owl-nav .owl-next:before,
#testimonial-transparent .owl-nav .owl-prev:before {
	content:'\f105';
	color:#fff;
	text-align:center;
	font-family:fontawesome;
	font-size: 20px;
	margin:0 auto;
	line-height: 56px;
	display:block;
}
#testimonial-transparent .owl-nav .owl-prev:before {
	content:'\f104';
}
.testimonial {
	float:left;
	width:100%;
	background:#FFF;
	padding: 60px 80px;
	position:relative;
	margin-bottom: 70px;
}
.testimonial.grey { background:#fcfafa; }
.testimonial h4 {
	font: 26px "Lato";
	color:#000;
	letter-spacing:1px;
	text-transform:uppercase;
}
.testimonial span {
	float:left;
	width:100%;
	padding: 10px 0px 20px 0px;
	font: 300 13px "Lato";
	color:#cd3000;
	letter-spacing:0.2px;
}
.testimonial p {
	float:left;
	width:100%;
	padding-bottom:40px;
	font: 15px/30px "Lato";
	color:#5c5b5b;
	letter-spacing:0.4px;
}
.testimonial-thumb {
	width: 140px;
	height: 140px;
	position: absolute;
	left:20%;
	top:100%;
	margin-top:-70px;
	z-index: 9999999;
	
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	-webkit-border-radius: 100%;
	-o-border-radius: 100%;
	border-radius: 100%;
}
.testimonial-thumb img {
	float:left;
	width:100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	-webkit-border-radius: 100%;
	-o-border-radius: 100%;
	border-radius: 100%;
}
.testimonial-carousel .owl-dots {
	position:absolute;
	left:50%;
	top:100%;
	margin-left: -15px;
}
.testimonial-carousel .owl-dot { float:left; margin-right: 10px; }
.testimonial-carousel .owl-dot:before {
    color: #fff;
    content: "\f111";
    font-family: fontawesome;
}
.testimonial-carousel.light .owl-dot:before { color:#ebebeb; }
.testimonial-carousel .owl-dot.active:before {
	color:#e65650;
}
.title.white h2,
.title.white p {
	color:#FFF;
	z-index: 999999;
	position:relative;
}
.skills-list {
	float:left;
	width:100%;
	position:relative;
}
.skills {
    float: left;
    margin-top: 30px;
    text-align: center;
    width: 100%;
}
.skill-count {
    float: left;
    position: relative;
    width: 100%;
	z-index: 1;
}
.skill-count i {
	display:inline-block;
	width: 55px;
	height: 55px;
	background:#e65650;
	color: #fff;
	position:absolute;
	left:50%;
	top:20%;
	margin-left:-30px;
	border-radius: 100%;
	line-height: 55px;
	font-size: 24px;
}
.circliful {
    display: block;
    float: none;
    height: 250px;
    margin: 0 auto;
    position: relative;
}
.circliful::before {
    background: none repeat scroll 0 0 #ffffff;
    border-radius: 50%;
    content: "";
    height: 88%;
    left: 50%;
    margin-left: -44%;
    position: absolute;
    top: 14px;
    width: 88%;
	z-index: -1;
}
.circle-text {
    color: #000000;
    font-family: intro;
    font-size: 35px;
    left: 0;
    padding-top: 31px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
}
.skills.black > span { color:#303030; }
.skills > span {
    color: #fff;
    float: left;
    font-family: Lato;
    font-size: 19px;
    font-weight: bold;
    letter-spacing: 0.3px;
    margin-top: 16px;
    width: 100%;
	text-transform:uppercase;
}

		/****** Bottom Information ShortCode *****/	

.bottom-info,
.bottom-info img,
.info-box {
	width:100%;
	float:left;
	position:relative;
	text-align:center;
}
.info-box .info-overlay {
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.7);
}
.info-box .info-overlay i {
	float:left;
	width:100%;
	font-size: 60px;
	color:#fff;
	margin:60px 0px 20px 0px;
}
.info-box .info-overlay p {
	margin:0 auto;
	width: 80%;
	font: 25px/40px "Lato";
	color:#FFF;
	letter-spacing:1px;
}
			
		/****** Footer CopyRight .... 	*****/
		
.copyright {
	width: 100%;
	float:left;
	height: 95px;
	background:#0e0e0e;
	text-align:center;
}
.copyright p {
	width: 100%;
	float:left;
	text-align:center;
	font: 15px/95px "Lato";
	color:#a8a8a8;
	letter-spacing:1px;
}

			/*****	Demo Panel 	*****/
.styles-list {
	padding: 10px 0px;
	width: 200px;
	height: 420px;
	background:#414141;
	position:fixed;
	left:100%;
	top:100%;
	margin-left:-210px;
	/*margin-top:-420px;*/
	z-index: 99999;
	text-align:center;
	overflow: visible;
	-moz-transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
	-ms-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	 transition: all 0.3s ease-in;
}
.styles-list.up {
	margin-top:-420px;
}
.demo-panel  {
	float:left;
	width:100%;
	position:relative;
}
.mCSB_inside > .mCSB_container { margin-right: 0px !important; }
.demo-panel .demo-style {
	float:left;
	width:100%;
	position:relative;
}
.demo-thumb { width:100%; float:left; padding: 10px 0px; }
.demo-thumb img { display: inline-block; }
.demo-banner {
	position:fixed;
	left:50%;
	top:50%;
	margin-top: -103px;
	margin-left:-2px;
	opacity: 0;
	visibility:hidden;
	-moz-transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
	-ms-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	 transition: all 0.3s ease-in;
}
.demo-banner img {
	float:left;
	width:100%;
}
.demo-thumb:hover  + .demo-banner  { opacity: 1; visibility:visible; }
.btn-mov {
	position:fixed;
	left:100%;
	/*top:27%; */
	top: 93%;
	height: 30px;
	width: 30px;
	background:#222;
	margin-left:-209px;
	z-index:99999;
	text-align:center;
	margin-top: -2px;
	-moz-transition: all 0.3s ease-in;
	-webkit-transition: all 0.3s ease-in;
	-ms-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	 transition: all 0.3s ease-in;
}
.btn-mov.up { top: 29%; margin-top: 1px; }
.btn-mov a {
	display:block;
	text-align:center;
	color:#FFF;
	height: 45px;
	width: 199px;
	background:#e65650;
	font: bold 16px/45px "Lato";
	letter-spacing:0.4px;
	text-transform:uppercase;
}

/*****	Writer		*****/
.sidebar {
	width: 35%;
	float:left;
	background:#fff;
}
.writer-thumb {
	float:left;
	width:100%;
	position:relative;
}
.writer-thumb img {
	float:left;
	width:100%;
}
.writer-thumb a {
	display:inline-block;
	height: 65px;
	width: 260px;
	background:#f0422a;
	font: bold 15px/65px "Lato";
	color:#FFF;
	text-align:center;
	position:absolute;
	left:50%;
	margin-left: -130px;
	top:80%;	
}
.writer-thumb a i {
	padding-right: 10px;
	color:#FFF;
	font-size: 15px;
}
.writer-info {
	float:left;
	width:100%;
	padding: 130px 30px 70px 100px;
}
.writer-info > h4 {
	float:left;
	width:100%;
	font: 20px "Lato";
	color:#333333;
	letter-spacing:0.5px;
}
.writer-info h1 {
	float:left;
	width:100%;
	font: 28px "Intro";
	color:#0d0d0d;
	letter-spacing:2px;
	padding: 10px 0px 5px 0px;
	text-transform:uppercase;
}
.writer-info > span {
	float:left;
	width:100%;
	font: 14px "Noticia Text";
	color:#e65650;
	letter-spacing: 0.5px;
	text-transform:uppercase;
}
.writer-info ol {
	float:left;
	width:100%;
	padding: 20px 0px;
}
.writer-info ol li {
	display:block;
}
.writer-info ol li i {
	float:left;
	padding-right: 10px;
	font-size: 18px;
	color:#000;
}
.writer-info ol li h4 {
	float:left;
	font: 18px "Intro";
	color:#000;
	text-transform: uppercase;
}
.writer-info ol li span {
	float:left;
	width:100%;
	padding: 20px 0px 30px 0px;
}
.author-articles .title.style1 {
	width: 66.3%;
	padding-top: 70px;
}
.author-articles .title.style1 h2 { 
	font-size: 30px;
}
.author-articles .title.style1 p {
	font-size: 16px;
}
.author-articles {
	float:left;
	width: 65%;
	background:#fafafa;
	padding-bottom: 70px;
}
.article-list {
	float:left;
	width:80%;
	margin-bottom:-30px;
}
.article-box {
	float:left;
	width: 35%;
	margin: 0px 30px;
}
.article {
	float:left;
	width:100%;
	margin-bottom:30px;
}
.article-img {
	float:left;
	width: 100%;
	position:relative;
	border-bottom:2px solid #d64123;
}
.article img {
	float:left;
	width: 100%;
}
.writer-thumbnail {
	float:left;
	position:relative;
	position:absolute;
	left:10%;
	top:100%;
	margin-top:-40px;
}
.writer-thumbnail img {
	float:left;
	width:100%;
	border:2px solid #d64123;
	
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
	border-radius: 100%;
}
.article > span {
	padding-top: 50px;
	padding-bottom:5px;
	float:left;
	width:100%;
	font: 13px "Lato";
	color:#4f4f4f;
	letter-spacing:0.3px;
}
.article > h5 {
	padding-bottom: 20px;
	float:left;
	width:100%;
	font: bold 16px/28px "lato";
	color:#090909;
	letter-spacing:0.5px;
}
.article > a {
	display:inline-block;
	height: 35px;
	width: 110px;
	background:#f0422a;
	font: 12px/35px "Lato";
	text-align:center;
	text-transform:uppercase;
	color:#FFF;
}

/****** Model *****/
.model-info {
	float:left;
	width: 50%;
	background: #f8f8f8;
	padding: 45px 60px 50px 60px;
}
.model-info h4 {
	float:left;
	width:100%;
	font: 28px "Lato";
	color:#2d2d2d;
	letter-spacing:1px;
}
.model-info h1 {
	float:left;
	width:100%;
	font: 40px "Intro";
	color:#2d2d2d;
	letter-spacing:2px;
	padding: 12px 0px 15px 0px;
}
.model-info > span {
	padding-bottom: 30px;
	float:left;
	width:100%;
	font: 16px "Noticia Text";
	color:#e65650;
	letter-spacing: 0.5px;
}
.model-skills {
	padding-bottom: 30px;
	float:left;
	width:100%;
}
.model-skills h2 {
	width:100%;
	float:left;
	font: 40px "Dancing Script";
	color:#2d2d2d;
	letter-spacing:1px;
	text-transform:capitalize;
	position:relative;
}
.model-skills h2:before {
	content:'';
	background:#e65650;
	width: 30px;
	height: 3px;
	position:absolute;
	left:0;
	top:100%;
	margin-top: 12px;
}
.model-skills p {
	padding-top: 30px;
	float:left;
	width:100%;
	font: 14px/28px "Lato";
	color:#666666;
	letter-spacing:0.4px;
}
.model-skills i {
	float:left;
	width:100%;
	padding-top: 30px;
	font: bold 15px "Lato";
	color:#2d2d2d;
	padding-bottom:10px;
}
.model-skills span {
	float:left;
	width:100%;
	font: 15px "Lato";
	color:#666;
	letter-spacing: 0.4px;
	padding-bottom:10px;
}
.model-skills ol#mytabs {
	padding: 30px 0px;
	float:left;
	width:100%;
}
ol#mytab li { float:left; }
.nav-pills > li.active > a, 
.nav-pills > li > a:hover, 
.nav-pills > li > a:focus {
	background:none !important;
	color:#f00 !important;
	border-radius: 0px !important;
}
.model-skills ol li a { 
	float:left; 
	padding-right: 30px; 
	font: 15px "Dancing Script";
	color:#2d2d2d;
	letter-spacing:0.4px;
}
.model-skills ul { float:left; width:100%; }
.model-skills ul li { float:left; margin-right: 10px; width: 31%; }
.model-skills ul li img {
	float:left;
	width:100%;
}
.model-img {
	float:left;
	width: 50%;
}
.model-img img {
	float:left;
	width:100%;
}

/*****	Email Message Style 	*****/
.error-msg {
    background: #66b66a none repeat scroll 0 0;
    border-radius: 2px;
    float: left;
    padding: 10px 30px;
    width: 100%;
	display:none;
}
.error-msg > h6 {
    color: #fff;
    float: left;
    font: 600 14px "Roboto Slab";
    letter-spacing: 0.5px;
    text-transform: uppercase;
    width: 100%;
}
.error-msg > p {
    color: #eee;
    float: left;
    font: bold 11px "Open Sans";
    letter-spacing: 0.5px;
    padding-top: 10px;
    width: 100%;
}
.error-msg > p strong {
    color: #ff0;
    text-transform: uppercase;
}
.loader {
	float:left;
	width:100%;
	background:#fff;
	padding:10px;
	margin-bottom:15px;
	display:none;
}
.loader img {
	float:left;
}